import React, { useState, useEffect } from 'react';
import Wrapper from '@components/Wrapper';
import {useNavigate} from 'react-router-dom'
import {Layout, Breadcrumb, Table, Space, Tag, Button, Popconfirm, notification, Form, Tooltip} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faEdit,
  faTrash,
  faPlus,
  faImages,
  faGear,
  faCodeCompare,
  faLanguage,
  faStar, faDoorOpen, faCreditCard, faChartSimple, faEnvelope, faInfo
} from '@fortawesome/free-solid-svg-icons';
import { HotelService,HotelImageService, HotelPaymentOptionService,CurrencyService } from '@services';
import UploadHotelImageModalForm from '@components/hotels/UploadHotelImageModalForm';
import HotelLanguageModalForm from "../../components/hotels/HotelLanguageModalForm";
import WebLanguageService from "@services/web-languages/WebLanguage.service";
import VoucherModalForm from '../../components/vouchers/VoucherModalForm';
import HotelPaymentOptionsModalForm from "../../components/hotels/HotelPaymentOptionsModalForm";
import HotelSmtpSettingsModal from "../../components/hotels/HotelSmtpSettingsModal";
import {useSelector} from "react-redux";
import HotelAgencyInfoModalForm from "@components/hotels/HotelAgencyInfoModalForm";
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const Hotel = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const permissions = useSelector((state) => state.user.user.role.permissions);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploadModalVisible,setIsUploadModalVisible] = useState(false)
  const [isPaymentOptionsModalVisible,setIsPaymentOptionsModalVisible] = useState(false)
  const [isHotelSmtpSettingsModalVisible,setIsHotelSmtpSettingsModalVisible] = useState(false)
  const [isAgencyInfoModalVisible,setIsAgencyInfoModalVisible] = useState(false)
  const [hotels, setHotels] = useState({});
  const [hotelLanguages, setHotelLanguages] = useState([]);
  const [currency, setCurrency] = useState({});
  const [hotel, setHotel] = useState({});
  const [images, setImages] = useState({});
  const [webLanguage, setWebLanguage] = useState({});
  const [selectedHotel, setSelectedHotel] = useState();
  const [uploadModalForm] = Form.useForm();
  const [paymentOptionsModalForm] = Form.useForm();
  const [smtpSettingsModalForm] = Form.useForm();

  const [isWebLanguageModalVisible, setIsWebLanguageModalVisible] = useState(false);
  const [createLanguageModalForm] = Form.useForm();
  const [agencyInfoModalForm] = Form.useForm();
  const [isVoucherModalVisible,setIsVoucherModalVisible] = useState(false);

  useEffect(() => {
    getHotels();
    getWebLanguage();
    getCurrencies();
  }, []);

  const getHotels = async (page = 1) => {
    try {
      let { data: response } = await HotelService.get(page);
      setHotels(response);
      setIsLoading(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };
  
  const getHotel = async (hotelId) => {
    try {
      let { data: response } = await HotelService.findById(hotelId);
      setHotel(response.data);
      setIsVoucherModalVisible(true)
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };
  const getImages = async (id) => {
    try {
      let { data: response } = await HotelImageService.get(id);
      await getHotelLanguages(id)
      setImages(response);
      setIsUploadModalVisible(true);
      setSelectedHotel(id);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }
  const getWebLanguage = async () => {
    try {
      let { data: response } = await WebLanguageService.get();
      setWebLanguage(response);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }
  const onDelete = async (hotelId) => {
    try {
      let { data: response } = await HotelService.delete(hotelId);

      notification.success({
        message: response.message
      });
      setIsLoading(true);
      getHotels();
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const onCreate = async (values) => {
    values={ ...values,hotel_id:selectedHotel}
    try {
        let { data: response } = await WebLanguageService.create(values);

        if (response.success) {
          setIsWebLanguageModalVisible(false)
          getWebLanguage();
            notification.success({
                message: response.message
            });             
        }
    } catch (e) {
        if (e.response) {
            notification.error({
                message: e.response.message
            });
        }

        console.log(e);
    }
};


  const onUploadHotelImage = async () => {
    console.log('Hotel Resmi Yüklendi');
  }

  const getHotelPaymentOptions = async (hotelId) => {
    try {
      let { data: {data: response} } = await HotelPaymentOptionService.show(hotelId);
      paymentOptionsModalForm.setFields([
        { name: ['installments'], value: response.installments },
        { name: 'day_limit', value: response.day_limit }
      ])
      setIsPaymentOptionsModalVisible(true)
      setSelectedHotel(hotelId)
    } catch (e) {
      console.log(e.response)
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  const getHotelSmtpSettings = async (hotelId) => {
    setIsHotelSmtpSettingsModalVisible(true)
    /*try {
      let { data: {data: response} } = await HotelSmtpSettingService.show(hotelId);
      /!*setIsPaymentOptionsModalVisible(true)
      setSelectedHotel(hotelId)*!/
    } catch (e) {
      console.log(e.response)
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }*/
  }

  const getCurrencies = async () => {
    try {
      let { data: {data:response} } = await CurrencyService.get();
      setCurrency(response);
    } catch (e) {
      console.log(e.response)
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }
  const getHotelLanguages = async (hotelId) => {
    try {
      let { data: {data:response} } = await WebLanguageService.getHotelLanguage(hotelId);
      setHotelLanguages(response);
    } catch (e) {
      console.log(e.response)
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  const onHotelPaymentOptionsFinish = async (values) => {
    try {
      let { data: response } = await HotelPaymentOptionService.create(values,selectedHotel);
      console.log(response)
      if (response.success) {
        notification.success({
            message: response.message
        });
        setIsPaymentOptionsModalVisible(false)
        paymentOptionsModalForm.resetFields();
      }
    } catch (e) {
      console.log(e.response)
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  const getHotelAgencyInfo = async (hotelId) => {
      try{
        let { data: { data: response } } = await HotelService.findById(hotelId);
        agencyInfoModalForm.setFieldsValue({
          info: response.agency_info ? response.agency_info : ''
        })
        setSelectedHotel(hotelId)
        setIsAgencyInfoModalVisible(true)
      }catch (e){
        if (e.response){
          notification.error({
            message: e.response.data.message
          });
        }
      }
  }

  const onHotelAgencyInfoFinish = async (values) => {
    try {
      let { data: response } = await HotelService.updateAgencyInfo(values,selectedHotel);
      if (response.success) {
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  let dataColumns = [
    {
      title: '#',
      dataIndex: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: `${t('general.hotel_name')}`,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: `${t('general.status')}`,
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Tag color={record.is_active ? 'green' : 'red'}>{record.is_active ? `${t('general.active')}` : `${t('general.passive')}`}</Tag>
      )
    },
    {
      title: `${t('general.actions')}`,
      key: 'actions',
      render: (text, record) => (
        <Space align="middle">
          <Tooltip title={!permissions.includes('hotel.edit') ? `${t("general.not_authorized")}`: `${t("general.edit_hotel")}`}>
            <Button type="primary" disabled={!permissions.includes('hotel.edit')} href={`/hotels/${record.id}`}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Tooltip>

          <Popconfirm title={t("general.are_you_sure_delete")} onConfirm={() => onDelete(record.id)} disabled={!permissions.CAN_DELETE_HOTEL}>
            <Tooltip title={!permissions.includes('hotel.delete') ? `${t("general.not_authorized")}`: `${t("general.delete_hotel")}`}>
              <Button type="danger" disabled={!permissions.includes('hotel.delete')}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          </Popconfirm>

            <Tooltip title={t("general.images")}>
              <Button type="primary" onClick={() => getImages(record.id)}>
                <FontAwesomeIcon icon={faImages} />
              </Button>
            </Tooltip>

            <Tooltip title={t("general.settings")}>
              <Button type="primary" href={`/hotel-settings/${record.id}`}>
                <FontAwesomeIcon icon={faGear} />
              </Button>
            </Tooltip>

            <Tooltip title={t("general.concepts")}>
              <Button type="primary" href={`/hotels/${record.id}/concepts`}>
                <FontAwesomeIcon icon={faStar} />
              </Button>
            </Tooltip>

            <Tooltip title={t("general.rooms")}>
              <Button type="primary" href={`/hotels/${record.id}/room-definitions`}>
                <FontAwesomeIcon icon={faDoorOpen} />
              </Button>
            </Tooltip>

            <Tooltip title={t("general.hotel_room_match")}>
              <Button type="primary" href={`/hotel-room/match/${record.id}`}>
                <FontAwesomeIcon icon={faCodeCompare} />
              </Button>
            </Tooltip>

            <Tooltip title={t("general.languages")}>
              <Button type="primary" onClick={() => { setIsWebLanguageModalVisible(true);setSelectedHotel(record.id)}}>
                <FontAwesomeIcon icon={faLanguage} />
              </Button>
            </Tooltip>

            <Tooltip title={t("general.statistics")}>
              <Button type="primary" href={`/hotels/${record.id}/stats`}>
                <FontAwesomeIcon icon={faChartSimple} />
              </Button>
            </Tooltip>

          <Tooltip title={t("general.payment_settings")}>
            <Button type="primary" onClick={() => getHotelPaymentOptions(record.id)}>
              <FontAwesomeIcon icon={faCreditCard}/>
            </Button>
          </Tooltip>

          <Tooltip title={t("general.smtp_settings")}>
            <Button type="primary" onClick={() => getHotelSmtpSettings(record.id)}>
              <FontAwesomeIcon icon={faEnvelope}/>
            </Button>
          </Tooltip>

          <Tooltip title={t("general.information")}>
            <Button type="primary" onClick={() => getHotelAgencyInfo(record.id)}>
              <FontAwesomeIcon icon={faInfo}/>
            </Button>
          </Tooltip>

            <Tooltip title={t("general.voucher")}>
              <Button type="primary" onClick={() => getHotel(record.id)}>
                Voucher
              </Button>
            </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <Wrapper title="Hotels">
      <Content className="m-25">
        <Breadcrumb className="mb-20">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t("general.hotels")}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Space size="middle" className="mb-10">
            <Button type="primary" href="/hotels/create" disabled={!permissions.includes('hotel.create')} icon={<FontAwesomeIcon icon={faPlus} />}>
              {t("general.new_hotel")}
            </Button>
          </Space>

          <Table
            dataSource={hotels?.data}
            columns={dataColumns}
            loading={isLoading}
            rowKey={record => record.id}
            bordered
            pagination={{
              total: hotels.meta?.total,
              defaultPageSize: hotels.meta?.per_page,
              onChange: (page, pageSize) => {
                setIsLoading(true);
                getHotels(page);
              }
            }}
          />
          <UploadHotelImageModalForm
              isVisible={isUploadModalVisible}
              title={t("general.upload_hotel_image")}
              form={uploadModalForm}
              images={images}
              disabled={!permissions.includes('hotel.image.create')}
              getImages={getImages}
              hotelLanguages={hotelLanguages}
              selectedHotel={selectedHotel}
              onFinish={onUploadHotelImage}
              onCancel={() => {setIsUploadModalVisible(false);navigate('/hotels')}}
          />
        </div>
        <HotelLanguageModalForm
                isVisible={isWebLanguageModalVisible}
                title={t("general.hotel_web_language")}
                form={createLanguageModalForm}
                webLanguage={webLanguage}
                selectedHotel={selectedHotel}
                onFinish={onCreate}
                onCancel={() => setIsWebLanguageModalVisible(false)}
            />
            <VoucherModalForm 
              isVisible={isVoucherModalVisible}
              title={t("general.voucher")}
              hotel={hotel}
              onCancel={() => setIsVoucherModalVisible(false)}
            />
        <HotelPaymentOptionsModalForm
          isVisible={isPaymentOptionsModalVisible}
          title={t("general.payment_settings")}
          form={paymentOptionsModalForm}
          onCancel={() => setIsPaymentOptionsModalVisible(false)}
          onFinish={onHotelPaymentOptionsFinish}
          currency={currency}
        />
        <HotelSmtpSettingsModal
            title={t("general.smtp_settings")}
            isVisible={isHotelSmtpSettingsModalVisible}
            form={smtpSettingsModalForm}
            onCancel={() => setIsHotelSmtpSettingsModalVisible(false)}
            onFinish={(values) => console.log(values)}
        />
        <HotelAgencyInfoModalForm
            isVisible={isAgencyInfoModalVisible}
            title={t("general.information")}
            form={agencyInfoModalForm}
            onCancel={() => {
              agencyInfoModalForm.resetFields()
              setIsAgencyInfoModalVisible(false)
            }}
            onFinish={(values) => onHotelAgencyInfoFinish(values)}
        />
      </Content>
    </Wrapper>
  );
};

export default Hotel;