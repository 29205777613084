import React, { useEffect, useState } from 'react';

import {
    Layout,
    Breadcrumb,
    Button,
    Table,
    notification,
    Row,
    Col,
    Input,
    Select,
    Tabs,
    Card,
    Form,
    Checkbox,
    Tooltip,
    InputNumber,
    DatePicker,
    Space,
    Popconfirm,
    Tag,
    Timeline,
    Switch,
    Upload,
    message, Popover
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBan, faCalendarAlt,
    faCheck, faEye,
    faHouse,
    faMoneyBillTransfer, faMoneyBillWave, faPaperPlane, faPlus, faRefresh, faSave,
    faTrash, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { ReservationDetailService, AdditionalService, PaymentMethodsService, Authentication, ReservationNotesService, SafeBankDefinitionService, ReservationPaymentsService } from '@services';
import {useParams} from "react-router-dom";
import moment from "moment/moment";
import ReservationConfirmSendModal from "../../components/reservations/ReservationConfirmSendModal";
import ReservationPaymentBalanceCompletionModalForm
    from "../../components/reservations/ReservationPaymentBalanceCompletionModalForm";
import {useSelector} from "react-redux";
import Loading from "@components/Loading";
import ReservationProformaSendModal from "@components/reservations/ReservationProformaSendModal";
import ReservationBalanceSendModal from "@components/reservations/ReservationBalanceSendModal";
import {useTranslation} from "react-i18next";
import ReservationPaymentTransferModalForm
    from "@components/reservations/ReservationPaymentTransferModalForm";
import AdditionalServiceProperties from "@/views/reservations/components/additional-service-properties";

const { Content } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const ReservationCard = () => {
    const { t } = useTranslation()
    const user = useSelector((state) => state.user.user);
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const [loading,setLoading]=useState(true);
    const [reservations,setReservations]=useState([]);
    const [reservationDiscountsTotal,setReservationDiscountsTotal]=useState(0);
    const [reservationNonDiscountedTotal,setReservationNonDiscountedTotal]=useState(0);
    const [isReservationCostLoading,setIsReservationCostLoading]=useState(false);
    const [isReservationProformaLoading,setIsReservationProformaLoading]=useState(false);
    const [isReservationBalanceLoading,setIsReservationBalanceLoading]=useState(false);
    const [isBalanceCompletion,setIsBalanceCompletion] = useState(false);
    const [showConfirmSendModal,setShowConfirmSendModal]=useState(false);
    const [showProformaSendModal,setShowProformaSendModal]=useState(false);
    const [showBalanceSendModal,setShowBalanceSendModal]=useState(false);
    const [isVoucherLoading,setIsVoucherLoading]=useState(false);
    const [isReservationFormLoading,setIsReservationFormLoading]=useState(false);
    const [showPaymentBalanceCompletionModal,setShowPaymentBalanceCompletionModal]=useState(false);
    const [selectedPayment,setSelectedPayment]=useState(null);
    const [selectedPaymentMethod,setSelectedPaymentMethod]=useState([]);
    const [selectedVirtualPos,setSelectedVirtualPos] = useState([]);
    const [paymentLink,setPaymentLink] = useState("");
    const [reservationNotes,setReservationNotes] = useState({});
    const [paymentMethods,setPaymentMethods] = useState({
        error:false,
        loading:true,
        data:[]
    })
    const [virtualPoses,setVirtualPoses] = useState({
        error:false,
        loading:true,
        data:[]
    })
    const [safeBankDefinitions,setSafeBankDefinitions] = useState({
        error:false,
        loading:true,
        data:[]
    })
    const [reservationPayments,setReservationPayments] = useState({
        error:false,
        loading:true,
        data:[],
        total_paid: 0,
        total_processed: 0
    })
    const [reservationPaymentLinks,setReservationPaymentLinks] = useState({
        error:false,
        loading:true,
        data:[]
    })
    const [additionalServices,setAdditionalServices] = useState({
        error:false,
        loading:true,
        data:[]
    })
    const params= useParams()
    const [reservationPaymentForm] = Form.useForm()
    const [reservationAdditionalServicesForm] = Form.useForm()
    const [reservationCostForm] = Form.useForm()
    const [reservationPaymentLinkForm] = Form.useForm()
    const [reservationNotesForm] = Form.useForm()
    const [reservationConfirmSendForm] = Form.useForm()
    const [reservationProformaSendForm] = Form.useForm()
    const [reservationBalanceSendForm] = Form.useForm()
    const [reservationPaymentBalanceCompletionForm] = Form.useForm()
    const additionalService = Form.useWatch('service_id', reservationAdditionalServicesForm);
    const reservationPaymentLinkProcessesColumns= [
        {
            title: 'Kart Numarası',
            key:'card_number',
            dataIndex: 'card_number',
            render:(text) => text ?? "Tanımsız"
        },
        {
            title: 'Banka Mesajı',
            key:'message',
            dataIndex: 'message',
            render:(text) => text ?? "Tanımsız"
        },
        {
            title: 'Müşteri Ip Adresi',
            key:'ip',
            dataIndex: 'ip',
            render:(text) => text ?? "Tanımsız"
        },
        {
            title: 'Durum',
            key:'status',
            dataIndex: 'status',
            render:(text) => {
                if(text == 1){
                    return <Tag color="yellow">Bekleniyor</Tag>
                }
                if(text == 2){
                    return <Tag color="green">Başarılı</Tag>
                }
                return <Tag color="red">Başarısız</Tag>
            }
        },
        {
            title: 'İşlem Tarihi',
            key:'created_at',
            dataIndex: 'created_at',
            render:(text) => moment(text).format("DD.MM.YYYY HH:mm:ss")
        }
    ]

    const onCheckStatusPayment = async (paymentId) => {
        try {
            let {data: response} = await ReservationPaymentsService.checkStatusPaymentLink(params.reservationId, paymentId);
            if(response.success){
                notification.success({
                    message: response.message
                });
                setTimeout(() => {
                    getReservationPaymentLinks()
                }, 3000)
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const reservationPaymentLinksColumns = [
        {
            title: `${t("general.link")}`,
            key: "url",
            dataIndex: "url",
            render: (text) => <a href={text} target="_blank" rel="noreferrer">Bağlantı</a>
        },
        {
            title: `${t("general.status")}`,
            key:'status',
            dataIndex: 'status',
            render:(text) => {
                let status = "Bekleniyor"
                if(text === 2){
                    status = "Zaman Aşımı"
                    return <Tag color="red">{status}</Tag>
                }
                if(text === 3){
                    status = "Başarısız"
                    return <Tag color="red">{status}</Tag>
                }
                if(text === 4){
                    status = "Ödeme Alındı"
                    return <Tag color="green">{status}</Tag>
                }
                return <Tag color="yellow">{status}</Tag>
            }
        },
        {
            title: `${t("general.agent")}`,
            key:'user',
            dataIndex: 'user',
            render:(text) => text?.full_name ?? "Tanımsız"
        },
        {
            title: `${t("general.amount")}`,
            key:'amount',
            dataIndex: 'amount',
            render:(text,record) => text + " " + record?.currency?.symbol
        },
        {
            title: `${t("general.bank")}`,
            key:'bank',
            dataIndex: 'bank',
            render:(text) => text?.bank_name ?? "Tanımsız"
        },
        {
            title: `${t("general.installment")}`,
            key:'installments',
            dataIndex: 'installments',
            render:(text) => text ?? "Peşin"
        },
        {
            title: `${t("general.is_balance_completion")}`,
            key:'is_balance_completion',
            dataIndex: 'is_balance_completion',
            render:(text) => text ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>
        },
        {
            title: `${t("general.balance_completion_payment")}`,
            key:'reservation_payment',
            dataIndex: 'reservation_payment',
            render:(text) => text?.safe_bank?.title ?? "Tanımsız"
        },
        {
            title: `${t("general.created_date")}`,
            key:'created_at',
            dataIndex: 'created_at',
            render:(text) => text
        },
        {
            title: `${t("general.language")}`,
            key:'locale',
            dataIndex: 'locale',
            render:(text) => text
        },
        {
            title: `${t("general.actions")}`,
            key:'actions',
            render:(text,record) => (
                <Space>
                    <Popconfirm title="Bu işlemi sadece müşteriden ödemesini tamamladığına dair onay aldığınızda gerçekleştiriniz!" onConfirm={() => onCheckStatusPayment(record.id)}>
                        <Button type="primary" htmlType="button">
                            <FontAwesomeIcon icon={faRefresh}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const reservationPaymentCompletionsColumns = [
        {
            title: 'Ödeme Yeri',
            key:'safe_bank',
            dataIndex: 'safe_bank',
            render:(text) => text?.title
        },
        {
            title: 'Tutar',
            key:'amount',
            dataIndex: 'amount',
            render:(text,record) => text
        },
        {
            title: 'Açıklama',
            key:'description',
            dataIndex: 'description',
            render:(text) => text
        },
        {
            title: 'İşlemi Yapan',
            key:'agent',
            dataIndex: 'agent',
            render:(text) => text?.full_name ?? "Otomatik Sistem Onayı"
        },
        {
            title: 'İşlem Tarihi',
            key:'created_at',
            dataIndex: 'created_at',
            render:(text) => text
        },
        {
            title: 'İşlemler',
            key:'actions',
            dataIndex: 'actions',
            render:(text,record) => (
                <Space>
                    {record?.safe_bank?.payment_method_id !== 7 &&
                        <Tooltip>
                            <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onBalanceCompletionDelete(record.id,record.reservation_payment_id)}>
                                <Button type="danger" htmlType="button">
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    }
                </Space>
            )
        }
    ]

    const additionalServicesColumns = [
        {
            title: '#',
            key:'id',
            dataIndex: 'id',
            render: (text,record,index) => index + 1
        },
        {
            title: `${t("general.service_name")}`,
            key:'additional_service_id',
            dataIndex: 'additional_service_id',
            render: (text) => additionalServices.data.find(item => item.id === text)?.name ?? "Tanımsız"
        },
        {
            title: `${t("general.quantity_price")}`,
            key:'price',
            dataIndex: 'price',
            render: (text, record) => text + " " + record?.currency?.symbol
        },
        {
            title: `${t("general.note")}`,
            key:'note',
            dataIndex: 'note',
            render: (text) => text
        },
        {
            title: `${t("general.created_date")}`,
            key:'created_at',
            dataIndex: 'created_at',
            render: (text) => moment(text).format('DD.MM.YYYY HH:mm')
        },
        {
            title: `${t("general.actions")}`,
            key:'actions',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Sil">
                        <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onAdditionalServiceDelete(record.reservation_id, record.id)}>
                            <Button type="danger" htmlType="button">
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const onDeleteReservationPaymentDocument = async (paymentId, documentId) => {
        try {
            let {data: response} = await ReservationPaymentsService.deleteDocument(paymentId, documentId);
            if(response.success){
                getReservationPayments()
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }

    const reservationPaymentsColumns = [
        {
            title: '#',
            key:'id',
            dataIndex: 'id',
            render: (text,record,index) => index + 1
        },
        {
            title: `${t("general.payment_type")}`,
            key:'safe_bank',
            dataIndex: "safe_bank",
            render:(text) => text?.paymentMethod?.name
        },
        {
            title: `${t("general.payment_type")}`,
            key:"bank_name",
            dataIndex: "safe_bank",
            render:(text) => text?.bank_name
        },
        {
            title: `${t("general.amount")}`,
            key:"amount",
            dataIndex: "amount",
            render:(text,record) => (
                <Space size="small">
                    {text}
                    {record?.safe_bank?.currency?.symbol}
                </Space>
            )
        },
        {
            title: `${t("general.agent")}`,
            key:"agent",
            dataIndex: "agent",
            render:(text) => text?.full_name
        },
        {
            title:`${t("general.due_date")}`,
            key:"fall_due",
            dataIndex: "fall_due",
            render:(text) => text && moment(text).format('DD.MM.YYYY')
        },
        {
            title:`${t("general.process_date")}`,
            key:"process_date",
            dataIndex: "process_date",
            render:(text) => moment(text).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            title:`${t("general.approver")}`,
            key:"approver",
            dataIndex: "approver",
            render:(text,record) => {
                if(!text && record?.approval_date){
                    return "Otomatik Sistem Onayı"
                }
                return text?.full_name
            }
        },
        {
            title:`${t("general.approval_date")}`,
            key:"approval_date",
            dataIndex: "approval_date",
            render:(text) => text && moment(text).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            title:`${t("general.description")}`,
            key:"description",
            dataIndex: "description",
            render:(text) => text
        },
        {
            title:`${t("general.approval_status")}`,
            key:"approved",
            dataIndex: "approved",
            render:(text) => {
                let message = "Bekleniyor"
                if(text === 1){
                    message = "Onaylandı"
                    return( <Tag color="green">{message}</Tag>)
                }
                if(text === 2){
                    message = "İptal Edildi"
                    return( <Tooltip title="Tüm ücret müşteriye iade edilmiştir."><Tag color="red">{message}</Tag></Tooltip>)
                }
                if(text === 0){
                    message = "Reddedildi"
                    return( <Tag color="red">{message}</Tag>)
                }
                return( <Tag>{message}</Tag>)
            }
        },
        {
            title: `${t("general.status")}`,
            key:"status",
            dataIndex: "status",
            render:(text,record) => {
                let message = "Ödenmedi"
                // burada rezervasyonun alt ödemelerini toplayıp rezervasyon ödemesi ile eşitse ödendi olarak göstereceğiz.
                let totalBalance = 0;
                record?.balance_completions?.map((item) => {
                    totalBalance += item.amount
                })
                if(record.approved === 1 && record?.safe_bank?.paymentMethod?.id !== 4){
                    message = "Ödendi"
                    return <Tag color="green">{message}</Tag>
                }
                if(record.approved === 1 && record?.safe_bank?.paymentMethod?.id === 4 && record?.amount <= totalBalance){
                    message = "Ödendi"
                    return <Tag color="green">{message}</Tag>
                }
                return <Tag color="red">{message}</Tag>
            }
        },
        {
            title: `${t("general.documents")}`,
            key:"files",
            dataIndex: "files",
            render:(text,record) => <div style={{
                display:"grid",
                gridTemplateColumns:"repeat(2,1fr)",
                gap:"10px"
            }}>
                {/*<a key={key} href={`https://${item?.url}`} target="_blank" rel="noreferrer" style={{
                whiteSpace:"nowrap",
            }}>İndir</a>*/}
                {record?.documents?.map((item, key) => (
                    <Popover
                        key={key}
                        content={<Space>
                            <a key={key} href={`https://${item?.url}`} target="_blank" rel="noreferrer" style={{
                                whiteSpace:"nowrap",
                            }}>
                                <Button htmlType="button" type="primary" icon={<FontAwesomeIcon icon={faSave}/>}/>
                            </a>
                            <Button type="danger" htmlType="button" onClick={() => onDeleteReservationPaymentDocument(record.id, item.id)} icon={<FontAwesomeIcon icon={faTrash}/>}/>
                        </Space>}
                        title="İşlemler"
                        trigger="click"
                    >
                        Dosya {key + 1}
                    </Popover>

                ))}
            </div>
        },
        {
            title: `${t("general.actions")}`,
            key:"actions",
            render:(text,record) => (
                <Space>
                    <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onDeleteReservationPayment(record.id)} okText="Sil" cancelText="İptal">
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                    <Popconfirm title={`Ödeme rezervasyon fişinde ${record?.is_visible_on_invoice ? "gizlenecektir.": "gösterilecektir."}`} onConfirm={() => onHideReservationPayment(record.id)} okText="Tamam" cancelText="İptal">
                        <Button style={{
                            backgroundColor: record?.is_visible_on_invoice ? "#b7eb8f": "#ff4d4f",
                            color: record?.is_visible_on_invoice ? "#389e0d": "#fff",
                        }}>
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Popconfirm>
                    {record.approved === null &&
                        <>
                            <Popconfirm title="Onaylamak istediğinize emin misiniz?" onConfirm={() => onApprovedReservationPayment(record.id,true)} okText="Onayla" cancelText="İptal">
                                <Button type="primary">
                                    <FontAwesomeIcon icon={faCheck}/>
                                </Button>
                            </Popconfirm>
                            <Popconfirm title="Reddetmek istediğinize emin misiniz?" onConfirm={() => onApprovedReservationPayment(record.id,false)} okText="Reddet" cancelText="İptal">
                                <Button type="danger">
                                    <FontAwesomeIcon icon={faBan}/>
                                </Button>
                            </Popconfirm>
                        </>
                    }
                    {record.approved === 1 && record?.safe_bank?.paymentMethod?.id === 4 &&
                        <>
                            <Tooltip title={!permissions.includes('reservation.payment.balance.create') ? "Bu işlem için yetkiniz bulunmuyor.": "Bakiye Tamamlama Ödemesi"} placement="left">
                                <Button type="primary" disabled={!permissions.includes('reservation.payment.balance.create')} onClick={() => onBalanceCompletion(record.id)}>
                                    <FontAwesomeIcon icon={faMoneyBillWave}/>
                                </Button>
                            </Tooltip>
                        </>
                    }
                    {(record.approved === 1 && record?.safe_bank?.paymentMethod?.id === 7 && user?.is_super_admin) &&
                        <ReservationPaymentTransferModalForm
                            title={t("general.virman")}
                            payment={record}
                            onSuccess={() => getReservationPayments()}
                            isDisabled={!user?.is_super_admin}
                        />
                    }
                    <Tooltip title="Belge Yükle">
                        <Upload
                            name="file"
                            multiple={true}
                            action={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/v1/reservation-payments/${record.id}/upload-document`}
                            headers={{
                                Authorization: `Bearer ${Authentication.getToken()}`
                            }}
                            showUploadList={false}
                            onChange={(info) => {
                                if (info.file.status === 'done') {
                                    message.success(`${info.file.name} dosyası başarıyla yüklendi.`);
                                    getReservationPayments();
                                } else if (info.file.status === 'error') {
                                    message.error(`${info.file.name} dosyası yüklenirken bir hata oluştu.`);
                                }
                            }}
                        >
                            <Button type="primary" htmlType="button">
                                <FontAwesomeIcon icon={faUpload} />
                            </Button>
                        </Upload>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const onAdditionalServiceDelete = async (reservationId, serviceId) => {
        try {
            let { data: response } = await ReservationDetailService.deleteAdditionalService(reservationId, serviceId);
            if (response.success) {
                await getReservations();
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onApprovedReservationPayment = async (paymentId,approved) => {
        let values = {
            approved:approved ? 1:0
        }
        try{
            let { data: response } = await ReservationPaymentsService.approve(paymentId,values);
            if(response.success){
                if(response.success){
                    getReservationPayments()
                    getReservations()
                    notification.success({
                        message: response.message
                    });
                }
            }
        }catch (e){
            console.log(e)
            if (e.response) {
                console.log(e)
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onCancelPayment = async (paymentId) => {
        try{
            let { data: response } = await ReservationPaymentsService.cancel(paymentId);
            if(response.success){
                getReservationPayments()
                notification.success({
                    message: response.message
                });
            }else{
                notification.error({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getSafeBankDefinitions = async (paymentId) => {
        try {
            let { data: {data: safeBank} } = await SafeBankDefinitionService.getByReservationId(params.reservationId,paymentId);
            setSafeBankDefinitions(prevState => ({
                ...prevState,
                data:safeBank,
                loading: false
            }))
        } catch (e) {
            setSafeBankDefinitions(prevState => ({
                ...prevState,
                error: true,
                loading: false
            }))
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getSafeBankVirtualPoses = async () => {
        try {
            let { data: {data: virtualPos} } = await SafeBankDefinitionService.getByReservationId(params.reservationId, 7);
            setVirtualPoses(prevState => ({
                ...prevState,
                data:virtualPos,
                loading: false
            }))
        } catch (e) {
            setVirtualPoses(prevState => ({
                ...prevState,
                error: true,
                loading: false
            }))
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    useEffect(async () => {
        const getPaymentMethods = async () => {
            try {
                let { data: {data: payments} } = await PaymentMethodsService.get();
                setPaymentMethods(prevState => ({
                    ...prevState,
                    data:payments,
                    loading: false
                }))
            } catch (e) {
                setPaymentMethods(prevState => ({
                    ...prevState,
                    error: true,
                    loading: false
                }))
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        }

        async function fetchData() {
            await Promise.all([
                getPaymentMethods(),
                getReservations(),
                getReservationPayments(),
                getSafeBankVirtualPoses(),
                /*getCardTypes(),*/
                getReservationNotes(),
                getReservationPaymentLinks(),
                getReservationAdditionalServices(),
                getAdditionalServices()
            ])
        }

        fetchData().finally(() => setLoading(false))

        /*getPaymentMethods()
        getReservations()
        getReservationPayments()
        getSafeBankVirtualPoses()
        /!*getCardTypes()*!/
        getReservationNotes()
        getReservationPaymentLinks()*/
    }, []);
    /*const getCardTypes = async () => {
        try {
            let { data: response } = await CardTypeService.get();
            setCardTypes(response.data)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }*/

    const getReservationAdditionalServices = async () => {
        /*try {
            let { data: response } = await ReservationAdditionalServiceService.getByReservationId(params.reservationId);
            setReservationAdditionalServices(response.data)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }*/
    }

    const getAdditionalServices = async () => {
        try {
            let { data: response } = await AdditionalService.get();
            setAdditionalServices({
                data:response.data,
                loading:false
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onBalanceCompletion = async (paymentId) => {
        setSelectedPayment(paymentId)
        setShowPaymentBalanceCompletionModal(true)
    }

    const onBalanceCompletionDelete = async (balanceCompletionId,paymentId) => {
        try{
            let {data: response} = await ReservationPaymentsService.deleteBalanceCompletion(params.reservationId,paymentId,balanceCompletionId);
            if(response.success){
                getReservationPayments()
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onAddPaymentBalanceCompletion = async (values) => {
        try{
            let {data: response} = await ReservationPaymentsService.createBalanceCompletion(params.reservationId,selectedPayment,values);
            if(response.success){
                getReservationPayments()
                setShowPaymentBalanceCompletionModal(false)
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getReservations = async () => {
        try {
            let { data: response } = await ReservationDetailService.getById(params.reservationId);
            setReservations(response.data)
            let total = 0;
            let discountTotal = 0;
            response.data?.discounts?.map((item) => {
                total += item?.raw_price
            })
            response.data?.discounts?.map((item) => {
                discountTotal += item?.discounted_price
            })
            setReservationDiscountsTotal(total)
            setReservationNonDiscountedTotal(discountTotal)
            return response.data;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getReservationPaymentLinks = async () => {
        try{
            let { data: {data: response} } = await ReservationPaymentsService.getPaymentLink(params.reservationId);
            setReservationPaymentLinks(prevState => {
                return {
                    ...prevState,
                    data:response,
                    loading: false
                }
            })
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onCreateReservationPaymentLink = async (values) => {
        try{
            let { data: response } = await ReservationPaymentsService.createPaymentLink(params.reservationId, values);
            if(response.success){
                setPaymentLink(response.data)
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getReservationPayments = async () => {
        try {
            let { data: response } = await ReservationPaymentsService.getByReservation(params.reservationId);
            setReservationPayments(prevState => ({
                ...prevState,
                loading: false,
                data:response.data,
                total_paid: response.total_paid ?? 0,
                total_processed: response.total_processed ?? 0
            }))
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onDeleteReservationPayment = async (id) => {
        try{
            let { data: response } = await ReservationPaymentsService.delete(id);
            if(response.success){
                getReservationPayments()
                getReservations()
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onHideReservationPayment = async (id) => {
        try{
            let { data: response } = await ReservationPaymentsService.changeInvoiceVisibility(id);
            if(response.success){
                getReservationPayments()
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onCreateReservationPayment = async (values) => {
        values = {
            ...values,
            reservation_id:reservations?.id,
            fall_due: values?.fall_due ? moment(values?.fall_due).format('YYYY-MM-DD') : null
        }
        try{
            let { data: response } = await ReservationPaymentsService.create(values);
            if(response.success){
                getReservationPayments()
                getReservations()
                reservationPaymentForm.resetFields()
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onUpdateReservationCost = async (values) => {
        setIsReservationCostLoading(true)
        try{
            let { data: response } = await ReservationDetailService.updateReservationCost(params.reservationId, values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                setIsReservationCostLoading(false)
            }
        }catch (e){
            setIsReservationCostLoading(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSendReservationCost = async (values) => {
        setIsReservationCostLoading(true)
        try{
            let { data: response } = await ReservationDetailService.sendReservationCost(params.reservationId, values);
            if(response.success){
                reservationConfirmSendForm.resetFields()
                setShowConfirmSendModal(false)
                notification.success({
                    message: response.message
                });
            }
            setIsReservationCostLoading(false)
        }catch (e){
            setIsReservationCostLoading(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSendReservationProforma = async (values) => {
        try{
            let { data: response } = await ReservationDetailService.sendReservationProforma(params.reservationId, values);
            if(response.success){
                reservationProformaSendForm.resetFields()
                setShowProformaSendModal(false)
                notification.success({
                    message: response.message
                });
            }
            setIsReservationProformaLoading(false)
        }catch (e){
            setIsReservationProformaLoading(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSendReservationBalance = async (values) => {
        let formData = new FormData();
        formData.append('mails', JSON.stringify(values.mails));
        values.files.map((file, key) => {
            formData.append(`file_${key}`, file['originFileObj']);
        })
        try{
            let response = await ReservationDetailService.sendReservationBalance(params.reservationId, formData);
            if(response.status === 201){
                reservationBalanceSendForm.resetFields()
                setShowBalanceSendModal(false)
                notification.success({
                    message: "Mail başarıyla gönderildi."
                });
            }
            setIsReservationBalanceLoading(false)
        }catch (e){
            setIsReservationBalanceLoading(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onUpdateReservationNotes = async (values) => {
        try{
            let { data: response } = await ReservationNotesService.create(params.reservationId, values);
            if(response.success){
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const setIsCallCenterSale = async (value) => {
        value = {
            is_call_center_sale: value
        }
        try{
            let { data: response } = await ReservationDetailService.setCallCenterSale(params.reservationId, value);
            if(response.success){
                notification.success({
                    message: response.message
                });
                getReservations()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getReservationForm = async () => {
        setIsReservationFormLoading(true)
        try {
            let response = await ReservationDetailService.getReservationForm(
                params.reservationId
            );
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = params.reservationId + '_form.pdf';
            a.click();
            window.URL.revokeObjectURL(url);
            setIsReservationFormLoading(false)
            return response.data;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                });
            }
            setIsReservationFormLoading(false)
        }
    };
    const getReservationVoucher = async (lang) => {
        setIsVoucherLoading(true)
        try {
            let response = await ReservationDetailService.getVoucher(
                params.reservationId,
                lang
            );
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            let fileName = `${reservations?.reservationPeople?.[0]?.first_name}_${reservations?.reservationPeople?.[0]?.last_name}_${reservations?.uuid}.pdf`
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            setIsVoucherLoading(false)
            return response.data;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                });
            }
            setIsVoucherLoading(false)
        }
    };
    const getReservationNotes = async (values) => {
        try{
            let { data: response } = await ReservationNotesService.get(params.reservationId);
            setReservationNotes(response.data)
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onHandleSendConfirm = () => {
        if (!reservations?.is_paid){
            notification.error({
                message: "Ödemesi tamamlanmayan rezervasyonlar için confirme gönderme işlemi yapılamaz.",
            })
            return;
        }

        setShowConfirmSendModal(true)
    }

    const onAdditionalServiceCreate = async (values) => {
        try{
            let { data: response } = await ReservationDetailService.createAdditionalService(params.reservationId, values);
            if (response.success){
                notification.success({
                    message: response.message
                });
                reservationAdditionalServicesForm.resetFields()
                getReservations()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const handleAdditionalServiceChange = (value) => {
        let data = reservations?.available_services?.find((item) => item.id === value)
        reservationAdditionalServicesForm.setFieldsValue({
            values: undefined
        })
        if (data){
            reservationAdditionalServicesForm.setFieldsValue({
                price: data.price,
            })
        }else{
            reservationAdditionalServicesForm.setFieldsValue({
                price: null,
            })
        }
    }

    const TotalPrice = () => (
        <Row>
            <Col span={24}>
                <b>{t("general.total_reservation_amount")}:</b> {reservations?.total_fee} {reservations?.currency_id?.symbol}
            </Col>
            <Col span={24}>
                <b>{t("general.total_paid_amount")}:</b> {reservationPayments?.total_paid} {reservations?.currency_id?.symbol}
            </Col>
            <Col span={24}>
                <b>{t("general.remaining_amount")}:</b> {reservations?.total_fee - reservationPayments?.total_processed} {reservations?.currency_id?.symbol}
            </Col>
        </Row>
    )

    if(loading){
        return <Loading />
    }

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/reservations">
                        {t("general.reservations")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.reservation_card")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {reservations?.uuid}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {reservations?.reservationPeople?.[0]?.first_name} {reservations?.reservationPeople?.[0]?.last_name}
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t("general.general")} key="1">
                           <h1 style={{textAlign:'center',marginBottom:"1rem"}}> OTEL BİLGİLENDİRME FORMU</h1>

                            <Row gutter={[16, 16]} justify={"space-between"} style={{"marginBottom":"1rem"}}>
                                <Col>
                                    <h4 style={{color:'#40A9FF'}}>{t("general.agent")}</h4>
                                    <div style={{ display:'flex', flexDirection:'column'}}>
                                        { reservations?.users ?
                                            <>
                                                <p>{t("general.name")} : { reservations?.users?.first_name}</p>
                                                <p>{t("general.surname")} : { reservations?.users?.last_name}</p>
                                            </>:
                                            <p>{t("general.system_reservation")}</p>
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    <h4 style={{color:'#40A9FF'}}>
                                        {t("general.accommodation_and_registration")}
                                    </h4>
                                    <div style={{ display:'flex', flexDirection:'column'}}>
                                        <p>{t("general.reservation_no")} :  { reservations?.uuid }</p>
                                        <p>{t("general.check_in")} :  { reservations?.check_in }</p>
                                        <p>{t("general.check_out")} :  { reservations?.check_out }</p>
                                        <p>{t("general.reservation_date")} :  { reservations?.created_at }</p>
                                        <p>{t("general.nights")} :  { reservations?.night }</p>
                                    </div>
                                </Col>
                                <Col>
                                    <h4 style={{color:'#40A9FF'}}>
                                        {t("general.facility_information")}
                                    </h4>
                                    <div style={{ display:'flex', flexDirection:'column'}}>
                                        <p>{t("general.hotel_name")}: { reservations?.hotels?.name}</p>
                                        <p>{t("general.hotel_phone")}: { reservations?.hotel_details?.phone_number }</p>
                                        <p>{t("general.hotel_fax")}: </p>
                                        <p>{t("general.hotel_address")}: { reservations?.hotel_details?.address }</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                                <Col span={24}>
                                    <h4 style={{color:'#40A9FF'}}>
                                        {t("general.guest_list")}
                                    </h4>
                                    <p> {t("general.room")} 1 - {t("general.room_type")} : { reservations?.room_detail?.active_language?.name} : { reservations?.concept?.concept?.active_language?.name}</p>
                                    <hr />
                                    {reservations?.reservationPeople?.map(item =>{
                                        return(
                                        <Row key={item.id} justify={'space-between'}>
                                            <Col span={6}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5>{t("general.full_name")}</h5>
                                                    <p style={{overflow:'auto'}}>{item.first_name +' '+ item.last_name} {item?.type === 2 && "(Çocuk)"}</p>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5>{t("general.nationality")}</h5>
                                                    <p>
                                                        {
                                                            item?.country_of_birth ?
                                                            item?.country_of_birth?.nationality:
                                                            ""
                                                        }
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5>{t("general.birth_date")}</h5>
                                                    <p>{moment(item.birth_date).format('DD.MM.YYYY')}</p>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5>{t("general.contact_info")}</h5>
                                                    {item?.phone_number || item?.email ?
                                                        <p>{item?.phone_number} | {item?.email}</p>:
                                                        <p>-</p>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        )

                                    })

                                    }

                                </Col>
                            </Row>

                        </TabPane>
                        <TabPane tab={t("general.notes")} key="2">
                            <Form form={reservationNotesForm} onFinish={onUpdateReservationNotes}>
                                <Row  gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                                    <Col span={12}>
                                    <Card title={t("general.guest_note")} style={{backgroundColor:'#B1D8B7'}}>
                                        <Form.Item name="guest_note" initialValue={reservationNotes?.guest_note}>
                                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                                        </Form.Item>
                                    </Card>
                                    </Col>
                                    <Col span={12}>
                                    <Card title={t("general.hotel_reservation_note")} style={{backgroundColor:'#93c5fd'}}>
                                        <Form.Item name="hotel_reservation_note" initialValue={reservationNotes?.hotel_reservation_note}>
                                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                                        </Form.Item>
                                    </Card>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                                    <Col span={12}>
                                        <Card title={t("general.operation_note")} style={{backgroundColor:'#FFFEA8'}}>
                                            <Form.Item name="operation_note" initialValue={reservationNotes?.operation_note}>
                                                <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                                            </Form.Item>
                                        </Card>
                                    </Col>

                                    <Col span={12}>
                                        <Card title={t("general.reception_note")} style={{backgroundColor:'#FFC171'}}>
                                            <Form.Item name="reception_note" initialValue={reservationNotes?.reception_note}>
                                                <TextArea rows={4} style={{"marginBottom":"1rem",height:150}}/>
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                                    {(user?.is_super_admin || user?.id === reservations?.users?.id) && (
                                        <Col span={12}>
                                            <Card title={t("general.agent_note")} style={{backgroundColor:'#b27fb2'}}>
                                                <Form.Item name="agent_note" initialValue={reservationNotes?.agent_note}>
                                                    <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                                                </Form.Item>
                                            </Card>
                                        </Col>
                                    )}
                                    <Col span={12}>
                                        <Card title={t("general.agency_note")} style={{backgroundColor:'#e36200'}}>
                                            <Form.Item name="agency_note" initialValue={reservationNotes?.agency_note}>
                                                <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Button htmlType="submit" style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}}>KAYDET</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane tab={t("general.documents")} key="3">
                            <Row gutter={[16,16]} style={{columnGap:16,padding: 16}}>
                                <Popover content={
                                    <Space>
                                        <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("tr")}>TR</Button>
                                        <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("en")}>EN</Button>
                                        <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("de")}>DE</Button>
                                        <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("ru")}>RU</Button>
                                    </Space>
                                } trigger="click">
                                    <Button style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}} loading={isVoucherLoading}>
                                        {t("general.voucher")}
                                    </Button>
                                </Popover>
                                <Button style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}} onClick={getReservationForm} loading={isReservationFormLoading}>
                                    {t("general.reservation_receipt")}
                                </Button>
                                <Button onClick={() => onHandleSendConfirm()} style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}}>
                                    {t("general.send_confirm")}
                                </Button>
                                <Button
                                    style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}}
                                    type="primary"
                                    htmlType="button"
                                    onClick={() => setShowProformaSendModal(true)}
                                    icon={<FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 10}}/>}
                                >
                                    {t("general.send_proforma")}
                                </Button>
                                <Button
                                    style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}}
                                    type="primary"
                                    htmlType="button"
                                    onClick={() => setShowBalanceSendModal(true)}
                                >
                                    {t("general.balance_completion")}
                                </Button>
                            </Row>
                        </TabPane>
                        <TabPane tab={t("general.additional_services")} key="9">
                            <Form layout="vertical" form={reservationAdditionalServicesForm} onFinish={(values) => onAdditionalServiceCreate(values)}>
                                <Row gutter={[16,0]}>
                                    <Col span={4}>
                                        <Form.Item
                                            name="service_id"
                                            label={t("general.service_name")}
                                            rules={[{
                                                required: true,
                                                message: t("general.service_name") + " " + t("general.required")
                                            }]}
                                        >
                                            <Select
                                                placeholder="Hizmet Adı"
                                                onChange={(value) => handleAdditionalServiceChange(value)}
                                            >
                                                {additionalServices.data.map((item) => (
                                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name="price"
                                            label={t("general.service_price")}
                                            rules={[{
                                                required: true,
                                                message: t("general.service_price") + " " + t("general.required")
                                            }]}
                                        >
                                            <InputNumber
                                                placeholder={t("general.service_price")}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name="currency"
                                            label={t("general.currency")}
                                            rules={[{
                                                required: true,
                                                message: t("general.currency") + " " + t("general.required")
                                            }]}
                                        >
                                            <Select style={{ width: "100%" }} placeholder={t("general.currency")}>
                                                <Option value="TRY">TRY</Option>
                                                <Option value="USD">USD</Option>
                                                <Option value="EUR">EUR</Option>
                                                <Option value="GBP">GBP</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name="reduce_price"
                                            label={t("general.reduce_price_from_reservation")}
                                            valuePropName="checked"
                                        >
                                            <Checkbox/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            label="Ek Bilgi Ekle"
                                            name="values"
                                            rules={[
                                                {
                                                    required: additionalServices.data.find((item) => item.id === additionalService)?.required_fields?.length > 0,
                                                    message: "Ek bilgi eklemek zorunludur."
                                                },
                                                {
                                                    validator: async (rule, value) => {
                                                        if (!additionalServices.data.find((item) => item.id === additionalService)?.required_fields?.length > 0){
                                                            return Promise.resolve();
                                                        }
                                                        if (!value || !Object.keys(value).length > 0) {
                                                            return Promise.reject('Gerekli ek bilgileri eklemeniz gerekiyor.');
                                                        }
                                                        additionalServices.data.find((item) => item.id === additionalService)?.required_fields?.map((field) => {
                                                            if (!value[field.id]) {
                                                                return Promise.reject('Gerekli alanları doldurmanız gerekiyor.');
                                                            }
                                                        })
                                                        return Promise.resolve();
                                                    }
                                                }
                                            ]}
                                            hidden={!additionalServices.data.find((item) => item.id === additionalService)?.required_fields?.length > 0}
                                        >
                                            <AdditionalServiceProperties
                                                fields={additionalServices.data.find((item) => item.id === additionalService)?.required_fields}
                                                onFinish={(data) => {
                                                    reservationAdditionalServicesForm.setFieldsValue({
                                                        values: data
                                                    })
                                                }}
                                            >
                                                <Button type="default" htmlType="button">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </AdditionalServiceProperties>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="note"
                                            label={t("general.note")}
                                        >
                                            <TextArea rows={4} placeholder={t("general.note")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span style={{color: 'transparent'}}>Ekle</span>}
                                        >
                                            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                                                {t("general.add")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item name="values" hidden={true}>
                                    </Form.Item>
                                </Row>
                            </Form>
                            <Table
                                columns={additionalServicesColumns}
                                dataSource={reservations?.additional_services}
                                rowKey={(record) => record.id}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <Row gutter={[16,4]}>
                                            {record.info?.map((item, index) => (
                                                <Col span={4} key={index}>
                                                    <strong>{item?.title}</strong> <br/>
                                                    {item?.value}
                                                </Col>
                                            ))}
                                        </Row>
                                    ),
                                    rowExpandable: (record) => record.info?.length > 0,
                                }}
                            />
                        </TabPane>
                        {/*<TabPane tab={t("general.guest_invoice")} key="4">
                                <Col span={12} style={{marginTop:'30px'}}>
                                <Form.Item
                                    name="ünvan"
                                    label="Ünvan"
                                    rules={[
                                        {
                                            type: 'string',
                                        },
                                    
                                        ]}>
                                <Input/>
                                </Form.Item>
                                </Col>

                                <Col span={12}>
                                <Form.Item
                                    name="adres"
                                    label="Adres"
                                ><Input/>
                                </Form.Item>
                                </Col>

                                <Col span={12}>

                                <Form.Item
                                    name="email"
                                    label="E-mail"
                                    rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                
                                    ]}><Input/>
                                </Form.Item>
                                </Col> 
                                <Col span={12}>
                                <Form.Item
                                    name="iletişim-numarası"
                                    label="İletişim Numarası"
                                    rules={[
                                    {
                                        type: 'phone',
                                        message: 'The input is not valid Phone',
                                    },
                                
                                    ]}><Input/>
                                </Form.Item>
                                </Col>

                                <Col span={12}>
                                <Form.Item
                                    name="vergi-dairesi"
                                    label="Vergi Dairesi"
                                ><Input/>
                                </Form.Item>
                                </Col>

                                <Col span={12} style={{marginBottom:'50px'}}>
                                <Form.Item
                                    name="vergi-no"
                                    label="Vergi Numarası/ TCKN"
                                    rules={[
                                        {
                                            type: 'number',
                                        },
                                    
                                        ]}>
                                 <Input/>
                                </Form.Item>
                                </Col>  
                                

                                <Checkbox.Group style={{ width: '100%' }}>
                                    <p><b>Birincil Kişiden Aktar</b></p>
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="ünvan">Ünvan</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="adres">Adres</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="e-mail">E-mail</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="iletişim-no">İletişim Numarası</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="vergi-dairesi">Vergi Dairesi</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="vergi-no">Vergi Numarası/TCKN</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>      
                        </TabPane>*/}
                        <TabPane tab={t("general.payments")} key="5">
                            <Form form={reservationPaymentForm} layout="vertical" onFinish={(values) => onCreateReservationPayment(values)}>
                                <Row gutter={[16,4]}>
                                    <Col span={8}>
                                        <Form.Item label={t("general.payment_type")}>
                                            <Select placeholder="Ödeme tipini seçiniz." onSelect={(selectedMethod) => {getSafeBankDefinitions(selectedMethod);setSelectedPaymentMethod(selectedMethod)}}>
                                                {paymentMethods.data?.map((payment)=>(
                                                    <Option value={payment.id} key={payment.id}>{payment.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={t("general.payment_type")} name="safe_bank_id" rules={[{
                                            required:true,
                                            message:"Ödeme yeri seçilmedi!"
                                        }]}>
                                            <Select disabled={safeBankDefinitions.data.length <= 0} placeholder="Ödeme yeri seçiniz.">
                                                {safeBankDefinitions.data?.map((safeBank)=>(
                                                    <Option key={safeBank.id} value={safeBank.id}>{safeBank.bank_name} - ({safeBank.title})</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={selectedPaymentMethod !== 4 ? 8:4}>
                                        <Form.Item
                                            name="amount"
                                            label={t("general.amount")}
                                            tooltip="Örn: 2460.50"
                                            rules={[
                                                {
                                                    required:true,
                                                    message:"Tutar girilmedi!"
                                                },
                                                {
                                                    validator: async (_,value) => {
                                                        if(value > reservations?.total_fee){
                                                            return Promise.reject(new Error('Toplam tutardan yüksek tutar girildi!'))
                                                        }
                                                    }
                                                }
                                            ]}>
                                            <InputNumber style={{width:"100%"}} min={0} placeholder="Tutar"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} hidden={selectedPaymentMethod !== 4}>
                                        <Form.Item label={t("general.due_date")} name="fall_due">
                                            <DatePicker
                                                format="DD.MM.YYYY"
                                                placeholder="Vade Tarihi"
                                                style={{width:"100%"}}
                                                disabledDate={(current) => {
                                                    return current && (current > moment(reservations?.check_in_raw).add(1, 'days') || current < moment().startOf('day'))
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={t("general.description")} name="description">
                                            <TextArea size="middle" placeholder="Açıklama giriniz."/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} style={{display:"flex",justifyContent:"flex-end"}}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">{t("general.add")}</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Table
                                columns={reservationPaymentsColumns}
                                dataSource={reservationPayments.data}
                                loading={reservationPayments.loading}
                                title={() => <TotalPrice/>}
                                bordered
                                rowKey={(record) => record.id}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <Table
                                            title={() => <h4>{t("general.balance_completion_payments")}</h4>}
                                            columns={reservationPaymentCompletionsColumns}
                                            dataSource={record?.balance_completions}
                                            rowKey={(record) => record.id}
                                            pagination={false}
                                            bordered
                                        />
                                    ),
                                    defaultExpandAllRows: true,
                                    rowExpandable: (record) => record?.balance_completions?.length > 0,
                                }}
                            />
                        </TabPane>
                        <TabPane tab={t("general.payment_link")} key="6">
                            <Form form={reservationPaymentLinkForm} layout="vertical" onFinish={onCreateReservationPaymentLink}>
                                <Row gutter={[16,4]}>
                                    <Col span={5}>
                                        <Form.Item
                                            label={t("general.virtual_pos")}
                                            name="virtual_pos_id"
                                            rules={[{
                                                required:true,
                                                message:"Sanal pos seçilmedi!"
                                            }]}
                                        >
                                            <Select onSelect={(id) => {setSelectedVirtualPos(id);reservationPaymentLinkForm.setFieldsValue({
                                                currency:undefined
                                            })}}>
                                                {virtualPoses.data?.map((safeBank)=>(
                                                    <Option key={safeBank.id} value={safeBank.id}>{safeBank.bank_name} - ({safeBank.title})</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name="currency"
                                            label={t("general.currencies")}
                                            rules={[{
                                                required:true,
                                                message:"Para birimi seçilmedi!"
                                            }]}
                                        >
                                            <Select placeholder="Lütfen para birimi seçiniz.">
                                                {virtualPoses.data?.find((pos) => pos.id === selectedVirtualPos)?.currencies?.map((currency) => (
                                                    <Option key={currency.id} value={currency.currency.id}>{currency.currency.code}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label={t("general.payment_amount")}
                                            name="amount"
                                            rules={[{
                                                required:true,
                                                message:"Ödeme miktarı giriniz!"
                                            }]}
                                        >
                                            <InputNumber
                                                placeholder="Ödeme miktarı giriniz."
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                min={0}
                                                style={{width:"100%"}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            label={t("general.installment")}
                                            name="installment"
                                        >
                                            <InputNumber
                                                min={2}
                                                style={{width:"100%"}}
                                                placeholder="Peşin için boş bırakınız."
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item
                                            label={t("general.language")}
                                            name="locale"
                                        >
                                            <Select
                                                placeholder={t("general.choose")}
                                            >
                                                <Option value="tr">{t("general.turkish")}</Option>
                                                <Option value="en">{t("general.english")}</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label={t("general.is_balance_completion")}
                                            name="is_balance_completion"
                                            valuePropName="checked"
                                        >
                                            <Switch onChange={(value) => setIsBalanceCompletion(value)}/>
                                        </Form.Item>
                                    </Col>
                                    {isBalanceCompletion && (
                                        <Col span={3}>
                                            <Form.Item
                                                label={t("general.balance_completion_payment")}
                                                name="reservation_payment_id"
                                                rules={[{
                                                    required:true,
                                                    message:"Rezervasyon ödemesi seçilmedi!"
                                                }]}
                                            >
                                                <Select>
                                                {reservationPayments.data?.map((payment) => {
                                                    if(payment.approved === 1 && payment?.safe_bank?.paymentMethod?.id === 4){
                                                        return(
                                                            <Option key={payment.id} value={payment.id}>{payment?.safe_bank?.bank_name} <br/>
                                                                <span style={{fontSize:"12px"}}>({payment?.amount})</span> - <span style={{fontSize:"12px"}}>({moment(payment?.fall_due).format('DD.MM.YYYY')})</span>
                                                            </Option>
                                                        )
                                                    }
                                                    return null;
                                                })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                                            {t("general.create_payment_link")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Row gutter={[16,16]} style={{marginTop:"16px"}}>
                                <Col span={24}>
                                    <Button type="secondary" style={{width:"100%"}} onClick={() => {navigator.clipboard.writeText(paymentLink).then(function (){
                                        notification.success({
                                            message:"Bağlantı kopyalandı!"
                                        });
                                    })}}>{paymentLink && paymentLink}</Button>
                                </Col>
                            </Row>
                            <Table
                                columns={reservationPaymentLinksColumns}
                                dataSource={reservationPaymentLinks.data}
                                loading={reservationPaymentLinks.loading}
                                bordered
                                pagination={false}
                                rowKey={(record) => record.id}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <Table
                                            title={() => <h4>Ödeme İşlemleri</h4>}
                                            columns={reservationPaymentLinkProcessesColumns}
                                            dataSource={record?.transaction_logs}
                                            rowKey={(record) => record.id}
                                            pagination={false}
                                            bordered
                                        />
                                    ),
                                    rowExpandable: (record) => record?.transaction_logs?.length > 0,
                                }}
                            />
                        </TabPane>
                        {permissions.includes('reservation.cost.edit') && (
                            <TabPane tab={t("general.cost")} key="7" hidden={true}>
                                <Form form={reservationCostForm} layout="vertical" onFinish={onUpdateReservationCost}>
                                    <Row gutter={[16,4]}>
                                        <Col span={6}>
                                            <Form.Item
                                                style={{ marginBottom: "6px" }}
                                                name="reservation_cost"
                                                label={t("general.cost")}
                                                initialValue={reservations?.cost}
                                                rules={[{
                                                    required:true,
                                                    message:"Tutar girilmedi!"
                                                }]}
                                            >
                                                <InputNumber style={{width:"100%"}}/>
                                            </Form.Item>
                                            <p>İndirimli Rez Tutarı: {reservations?.discounted_fee} {reservations?.currency_id?.symbol}</p>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="special_commission"
                                                label={t("general.special_commission")}
                                                initialValue={reservations?.special_commission}
                                            >
                                                <InputNumber style={{width:"100%"}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="cost_note"
                                                label={t("general.cost_note")}
                                                initialValue={reservations?.cost_note}
                                            >
                                                <TextArea style={{width:"100%"}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label={t("general.call_center_sale")}
                                                initialValue={reservations?.call_center_sale}
                                            >
                                                <Checkbox checked={reservations?.call_center_sale} onChange={(e) => setIsCallCenterSale(e.target.checked)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" style={{width:"100%"}} loading={isReservationCostLoading}>
                                                    {t("general.save")}
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24}>
                                        <Timeline>
                                            {reservations?.discounts?.map((discount,i) => (
                                                <Timeline.Item key={i} dot={<FontAwesomeIcon icon={faCalendarAlt}/>}>
                                                    <p>{moment(discount.date).format('DD.MM.YYYY')}</p>
                                                    {discount?.discounts &&
                                                        <p>
                                                            <b>Uygulanan İndirimler:</b> <br/>{discount?.discounts?.map((x,i) => (
                                                            <ul>
                                                                <li>{x.discount_name} {x.discount_type == 1 ? '%': '-'} {x.discount}</li>
                                                            </ul>
                                                        ))}
                                                        </p>
                                                    }
                                                    <p style={{fontWeight:"bold"}}>{t("general.discount_price")}: {discount?.discounted_price.toFixed(2)} {reservations?.currency_id?.symbol}</p>
                                                    <p style={{fontWeight:"bold"}}>{t("general.sale_price")}: {discount?.raw_price.toFixed(2)} {reservations?.currency_id?.symbol}</p>
                                                </Timeline.Item>
                                            ))}
                                        </Timeline>
                                    </Col>
                                    <Col span={24}>
                                        <b>{t("general.total_discount_price")}: {reservationNonDiscountedTotal.toFixed(2)} {reservations?.currency_id?.symbol}</b>
                                    </Col>
                                    <Col span={24}>
                                        <b>{t("general.total_sale_price")}: {reservationDiscountsTotal.toFixed(2)} {reservations?.currency_id?.symbol}</b>
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                        <TabPane tab={t("general.logs")} key="8">
                            <Row>
                                <Col span={24}>
                                    <Timeline>
                                        <Timeline.Item>
                                            <b>{reservations?.created_at}</b> <br/>
                                            <b>{reservations?.users ? `${reservations?.users?.first_name + ' ' + reservations?.users?.last_name}`: `Widget`}</b> tarafından <b>Rezervasyon</b> oluşturuldu.
                                        </Timeline.Item>
                                        {reservations?.logs?.map((log,i) => (
                                            <Timeline.Item key={i}>
                                                <b>{log?.date}</b> <br/>
                                                {log?.user ? <b>{log?.user?.first_name + " " + log?.user?.last_name}</b>: <b>Widget</b>} {log?.message}
                                            </Timeline.Item>
                                        ))}
                                    </Timeline>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </div>
            </Content>
            <ReservationConfirmSendModal
                title="Rezervasyon Confirme Gönder"
                form={reservationConfirmSendForm}
                isVisible={showConfirmSendModal}
                onCancel={() => setShowConfirmSendModal(false)}
                onFinish={onSendReservationCost}
                cost={reservations?.cost}
                currency={reservations?.currency_id}
                loading={isReservationCostLoading}
            />
            <ReservationProformaSendModal
                title="Rezervasyon Proforma Gönder"
                form={reservationProformaSendForm}
                isVisible={showProformaSendModal}
                onCancel={() => setShowProformaSendModal(false)}
                onFinish={onSendReservationProforma}
            />
            <ReservationBalanceSendModal
                title="Rezervasyon Bakiye Tamamlama Gönder"
                form={reservationBalanceSendForm}
                isVisible={showBalanceSendModal}
                onCancel={() => setShowBalanceSendModal(false)}
                onFinish={onSendReservationBalance}
                loading={isReservationBalanceLoading}
            />
            <ReservationPaymentBalanceCompletionModalForm
                title="Bakiye Tamamlama Ödemesi Ekle"
                form={reservationPaymentBalanceCompletionForm}
                isVisible={showPaymentBalanceCompletionModal}
                onCancel={() => setShowPaymentBalanceCompletionModal(false)}
                onFinish={onAddPaymentBalanceCompletion}
                selectedPayment={selectedPayment}
                paymentMethods={paymentMethods}
                getSafeBankDefinitions={getSafeBankDefinitions}
                safeBankDefinitions={safeBankDefinitions}
            />
        </Wrapper>

    );


};


export default ReservationCard;