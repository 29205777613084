import React, {useEffect, useState} from 'react';
import {
    Breadcrumb,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    notification,
    Row,
    Select, Skeleton,
    Tabs,
    Upload
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faPlus} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {LaraBeachTourService, LaraBeachSettingsService} from "@services";
import TextArea from "antd/es/input/TextArea";

const {Content} = Layout;
const {TabPane} = Tabs;
const {Option} = Select;

const TourDetailPage = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [supportedLanguages, setSupportedLanguages] = useState([])
    const [tour, setTour] = useState({
        data: {},
        loading: false
    })
    const tabs = [
        {
            title: "Genel Bilgiler",
            key: "general",
            content: <TourGeneralInfo data={tour.data} t={t} supportedLanguages={supportedLanguages}/>
        },
        {
            title: "Medyalar",
            key: "media",
            content: <TourMedia data={tour.data} />
        }
    ]
    const getSupportedLanguages = async () => {
        try {
            let {data: response} = await LaraBeachSettingsService.show('supported_languages')
            setSupportedLanguages(response.data.value.split(',') || [])
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getTour = async (id) => {
        setTour(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachTourService.show(id)
            setTour({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setTour(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    useEffect(() => {
        getTour(params.id)
        getSupportedLanguages()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/lara-beach-excursions/tours">
                            {t("general.tours")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.tour_detail")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    {tour.loading ? <Skeleton active />: (
                        <Tabs type="card">
                            {tabs.map((tab) => (
                                <TabPane tab={tab.title} key={tab.key} forceRender>
                                    {tab.content}
                                </TabPane>
                            ))}
                        </Tabs>
                    )}
                </div>
            </Content>
        </Wrapper>
    );
};

const TourGeneralInfo = ({ data, supportedLanguages, t }) => {
    const [generalForm] = Form.useForm();
    useEffect(() => {
        generalForm.setFieldsValue({
            general: {
                price: data.price,
                duration: data.duration,
                number_of_people: data.number_of_people,
                currency_id: data.currency_id,
                translations: data.translations?.reduce((acc, item) => {
                    acc[item.language] = {
                        title: item.title,
                        description: item.description,
                        location: item.location
                    };
                    return acc;
                }, {})
            }
        })
    },[data])
    return (
        <Form form={generalForm} layout="vertical">
            <Tabs>
                {supportedLanguages.map((language) => (
                    <TabPane tab={t(`general.${language}`)} key={language} forceRender>
                        <Row gutter={[16,2]}>
                            <Col span={12}>
                                <Form.Item
                                    label={`Tur Adı (${language})`}
                                    name={['general', 'translations', language, 'title']}
                                    rules={[{ required: true, message: 'Lütfen tur adını giriniz!' }]}
                                >
                                    <Input placeholder="Tur Adı" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={`Lokasyon (${language})`}
                                    name={['general', 'translations', language, 'location']}
                                    rules={[{ required: true, message: 'Lütfen lokasyon giriniz!' }]}
                                >
                                    <Input placeholder="Lokasyon" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={`Açıklama (${language})`}
                                    name={['general', 'translations', language, 'description']}
                                    rules={[{ required: true, message: 'Lütfen açıklama giriniz!' }]}
                                >
                                    <TextArea placeholder="Açıklama" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                ))}
            </Tabs>
            <Divider />
            <Row gutter={[16,2]}>
                <Col span={8}>
                    <Form.Item
                        label="Fiyat"
                        name={['general', 'price']}
                        rules={[{ required: true, message: 'Lütfen fiyatı giriniz!' }]}
                    >
                        <InputNumber placeholder="Fiyat" style={{ width: "100%" }} addonAfter={
                            <Form.Item noStyle name={['general','currency_id']}>
                                <Select placeholder={t("general.select")}>
                                    <Option value="TRY">TRY</Option>
                                    <Option value="USD">USD</Option>
                                    <Option value="EUR">EUR</Option>
                                </Select>
                            </Form.Item>
                        } />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tur Süresi"
                        name={['general', 'duration']}
                        rules={[{ required: true, message: 'Lütfen süreyi giriniz!' }]}
                    >
                        <InputNumber placeholder="Tur Süresi" style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Kişi Sayısı"
                        name={['general', 'number_of_people']}
                        rules={[{ required: true, message: 'Lütfen kişi sayısını giriniz!' }]}
                    >
                        <InputNumber placeholder="Kişi Sayısı" style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

const TourMedia = ({data}) => {
    const onRemoveImage = (file) => {
        console.log(file, 'remove')
    }
    const onUploadImage = (file) => {
        console.log(file, 'upload')
    }
    return (
        <Upload
            onRemove={(file) => onRemoveImage(file)}
            beforeUpload={(file) => {
                onUploadImage(file)
                return false
            }}
            listType="picture-card"
            fileList={data.files?.map((item) => ({
                uid: item.id,
                name: item.name,
                status: 'done',
                url: item.path
            })) || []}
        >
            <div>
                <FontAwesomeIcon icon={faPlus} />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        </Upload>
    )
}

export default TourDetailPage;