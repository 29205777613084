import axios from '../axios';

const HotelService = {
  async get(page = 1) {
    return await axios.get(`/v1/hotels?page=${page}`);
  },
  async getRooms(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/rooms`);
  },
  async search(formData) {
    return await axios.post(`/v1/searchAdmin`, formData);
  },
  async updateAgencyInfo(formData, hotelId) {
    return await axios.post(`/v1/hotels/${hotelId}/agency-info`, formData);
  },
  async findById(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}`);
  },

  async create(formData) {
    return await axios.post('/v1/hotels', formData);
  },

  async update(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}`, formData);
  },

  async delete(hotelId) {
    return await axios.delete(`/v1/hotels/${hotelId}`);
  }
}

export default HotelService;