import axios from '../../axios';

const ShoppingService = {
    async get(page = 1, per_page = 10){
        return await axios.get(`/lara-beach/shopping?page=${page}&per_page=${per_page}`);
    },
    async show(id){
        return await axios.get(`/lara-beach/shopping/${id}`);
    },
    async create(formData){
        return await axios.post('/lara-beach/shopping', formData);
    },
    async changePublishStatus(id){
        return await axios.post(`/lara-beach/shopping/${id}/change-published`);
    },
};

export default ShoppingService;